import React from "react"
import Loader from "react-loader-spinner"

const LoaderIcon = () => {
  return (
    <div className="flex items-center justify-center">
      <Loader type="Oval" color="#000000" height={30} width={30} />
    </div>
  )
}

export default LoaderIcon
